<template>
  <div v-if="compact" class="is-relative">
    <img
      v-if="imageUrl"
      :src="imageUrl"
      style="height: 2.5rem; max-height: none"
    />
    <img
      v-else
      src="~/assets/images/Transkribus.svg"
      style="height: 2.5rem; max-height: none"
    />

    <span
      v-if="isReady"
      class="brand-label is-small font-family-secondary is-uppercase has-text-weight-bold"
    >
    </span>
  </div>
  <div v-else class="is-relative">
    <h1
      v-if="isReady"
      class="brand-label is-size-6 font-family-secondary has-text-weight-bold is-uppercase"
    ></h1>
    <div>
      <img
        v-if="imageUrl"
        :src="imageUrl"
        style="height: 3.5rem; max-height: none"
      />
    </div>
  </div>
</template>

<style>
.brand-label {
  position: absolute;
  bottom: 0.15rem;
  left: 50%;
  transform: translateX(-50%);
  padding-left: 0.75rem;
  letter-spacing: 0.375rem;
  z-index: -1;
}

.brand-label.is-small {
  top: 50%;
  transform: translate(-50%, -50%);
  margin-left: -0.2rem;
  padding-top: 0.75rem;
  font-size: 0.75rem;
}
</style>

<script>
import { mapState } from 'pinia'
import { useSiteStore } from '~/stores/sites'
export default {
  props: {
    compact: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isReady: false,
      imageUrl: '',
    }
  },
  computed: {
    ...mapState(useSiteStore, ['language', 'rsCollection']),

    collId() {
      return this.$route.params.collectionid
    },
  },
  async mounted() {
    await this.load()
  },
  methods: {
    getIIIFImage(item, type) {
      if (item?.images && item?.images.find(i => i.type === type)) {
        let image = item.images.find(image => {
          return image.type === type
        })
        if (image) {
          return (
            'https://s3.readcoop.eu/api-sites-test/' +
            image.name +
            '.' +
            image.extension
          )
        }
      }
      return null
    },
    async load() {
      this.imageUrl = this.getIIIFImage(this.rsCollection, 'logo')
    },
  },
}
</script>
